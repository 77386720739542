import React from 'react';

import Typography from '../../components/Typography'
import Carousel from '../../components/Carousel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  prevButton: {
    backgroundColor: 'orange'
  },
  carouselItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px'
  },
  video: {
    width: '80%',
    maxHeight: '400px',
    maxWidth: '500px',
    marginBottom: '10px'
  },
  person: {
    minWidth: '360px',
    minHeight: '400px',
    padding: '10px',
    boxShadow: '2px 4px 10px #222',
    borderRadius: '5px',
    color: 'white'
  },
  comment: {
    lineHeight: '1.2',
    marginTop: '15px'
  },
  '@media screen and (min-width: 599px)': {
    bannerImage: {
      width: '100%',
      height: '100%',
      maxHeight: 'calc(90vh - 64px)',
    },
    person: {
      width: '500px',
      minHeight: '200px',
      height: '300px',
      padding: '10px 20px'
    },
  }
});

const testimonials = [{
  name: 'PALLAVI',
  type: 'Parent',
  comment: 'Sound Garage is very professional and easy to work with. My main concern was a teacher who could connect with my daughter given the classes are not in person and they are miles apart. I have personally experienced that a teacher can change how a student experiences music and the lessons. I am glad I found Sound Garage who helped my daughter with just that and glad to share that she is progressing really well.'
}, {  
  name: 'ARUN TYORE',
  type: 'Student',
  comment: 'Very good, simple and motivating way of teaching. Always available for help.'
}, {  
  name: 'CHAITANYA SATYAKUMAR',
  type: 'Parent',
  comment: 'Ayaan looks forward to learning . The teacher is patient , encouraging and keeps challenging my son . I am very happy that my son could learn from such a great place'
}, {
  name: 'PRIYANKA KHANNA',
  type: 'Parent',
  comment: 'Surprisingly, a one on one music class online can be effective and beyond productive, impossible as it may seem. Dedication, persistence, compassion and above all gracious are the virtues to name a few which has kept us strongly associated with Sound Garage. We have witnessed a tremendous growth over a period of one year plus due to their promising nature.'
}, {  
  name: 'YASHIKA RAVISHANKAR',
  type: 'Student',
  comment: 'Sound Garage is a very very fun and amazing teacher. He teaches quickly, but makes sure it is understandable by everyone. He teaches many fun songs, along with amazing exercises to help you play songs. I though learning Piano online would be impossible, but Sound Garage made it much better. He has a good view of his piano, so that everyone can see how he plays a song, or how his hands move. All in all, I would say that Sound Garage is a very fun, caring, and amazing teacher to have. He makes playing the piano the most enjoyable thing you could ever do!'
}]

const Gallery = (props) => {
  const { showNavButton = false } = props
  const classes = useStyles();

  return(
    <Carousel
      autoPlay={true}
      animation={'fade'}
      indicators={true}
      navButtonsAlwaysVisible={showNavButton}
      navButtonsProps={{
        style: classes.prevButton
      }}
      interval={5000}
      timeout={1500}
    >
      {
        testimonials.map((resource, index) => {
          const { name, type, comment } = resource
          return (
            <div key={index}>
              <div className={classes.carouselItem}>
                <div className={classes.person}>
                  <Typography variant="body1">{name}</Typography>
                  <Typography variant="subtitle1">{type}</Typography>
                  <Typography variant="body2" className={classes.comment}>{comment}</Typography>
                </div>
              </div>
            </div>
          )
        })
      }
    </Carousel>
  )
}

export default Gallery;