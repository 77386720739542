import React from 'react';
import { createGlobalStyle } from 'styled-components';

import LearousCarousel from 'react-material-ui-carousel'
import compStyles from './Carousel.module.scss'

const GlobalStyle = createGlobalStyle`
#mainbanner.Carousel-indicators-61 {
  position: absolute;
  bottom: 10px;
}
#mainbanner.Carousel-indicator-62 {
    font-size: 1.5em !important;
    color: #611a87 !important;
  }
#mainbanner.Carousel-active-63 {
  color: #27eed7 !important;
}

.Carousel-indicator-62 {
  font-size: 1.5em !important;
  color: #611a87 !important;
}
.Carousel-active-63 {
  color: #27eed7 !important;
}

#performances.Carousel-button-66  {
  background: white !important;
  border: 2px solid #611a87 !important;
}
#performances.Carousel-button-66.span {
  color: #611a87;
}
  
#performances.Carousel-buttonVisible-68 {
  background: white !important;
  border: 2px solid #611a87 !important;
}
#performances.Carousel-buttonVisible-68.span {
  color: #611a87;
}

#testimonials.Carousel-button-66  {
  background: none !important;
  border: 2px solid #fff !important;
}
#testimonials.Carousel-button-66.span {
  color: #fff;
} 
  
#testimonials.Carousel-buttonVisible-68 {
  background: none !important;
  border: 2px solid #fff !important;
}
#testimonials.Carousel-buttonVisible-68.span {
  color: #fff;
}
#testimonials.Carousel-indicator-62 {
  font-size: 1.5em !important;
  color: #fff !important;
}
#testimonials.Carousel-active-63 {
  color: #27eed7 !important;
}
`

const Carousel = (props) => {
  const {children, ...restProps} = props
  return (<div>
    <GlobalStyle />
    <LearousCarousel {...restProps} indicatorIconButtonProps={{
      className: compStyles.indicatorContainer
    }}>
      {children}
    </LearousCarousel>
  </div>)
}

export default Carousel