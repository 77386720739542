import React from 'react'

import Img from "gatsby-image"
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Typography from '../components/Typography'
import { useStaticQuery, graphql } from "gatsby"

import Banner from '../pageComponents/home/Banner'
import Gallery from '../pageComponents/home/Banner2'
import Testimonials from '../pageComponents/home/Banner3'
import compStyles from '../pageComponents/home/home.module.scss'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby'

const useStyles = makeStyles( theme => ({
  coursesContainer: {
    padding: '20px',
    border: '1px solid #611a87',
    borderRadius: '5px',
    margin: '20px',
    '@media screen and (min-width: 599px)': {
      margin: '40px'
    }
  },
  title: {
    textAlign: 'center',
    margin: '10px 0 20px'
  },
  courses: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  course: {
    maxWidth: '400px',
    border: '1px solid #611a87',
    borderRadius: '5px',
    position: 'relative',
    margin: '10px',
    boxShadow: '#aaa 1px 2px 7px 5px',
    '@media screen and (min-width: 599px)': {
      margin: '10px 20px'
    }
  },
  titleBackground: {
    height: '200px'
  },
  courseText: {
    padding: '10px',
    textAlign: 'center'
  },
  coursesButtonContainer: {
    width: 'fit-content',
    display: 'block',
    margin: '20px auto'
  },
  coursesButton: {
    width: '180px',
    color: '#fff',
    textAlign: 'center',
    padding: '5px 20px',
    backgroundColor: '#611a87',
    borderRadius: '20px'
  },
  aboutContainer: {
    padding: '20px',
    border: '1px solid #611a87',
    borderRadius: '5px',
    margin: '20px',
    '@media screen and (min-width: 599px)': {
      margin: '40px'
    }
  },
  aboutDetails: {
    maxWidth: '1200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto'
  },
  groupPhoto: {
    borderRadius: '5px',
    boxShadow: '#999 1px 2px 5px 5px',
    width: '80vw',
    minHeight: '220px',
    '@media screen and (min-width: 599px)': {
      minWidth: '400px',
      width: '400px',
      minHeight: '278px'
    }
  },
  aboutText1: {
    fontStyle: 'italic',
    fontWeight: '500',
    margin: '40px 0 20px'
  },
  aboutText2: {
    margin: '10px 0'
  },
  aboutText3: {
    fontWeight: '500',
    margin: '10px 0'
  },
  aboutText4: {
    lineHeight: '1.3',
    margin: '10px 0'
  },
  aboutBottom: {
    height: '40px',
    backgroundColor: '#611a87',
  },
  divider: {
    height: '0',
    borderTop: '1px solid #611a87',
    margin: '40px 0'
  },
  gallery: {
    maxWidth: '800px',
    paddingBottom: '40px',
    margin: '20px auto'
  },
  titleWhite: {
    textAlign: 'center',
    color: '#fff',
    paddingTop: '20px',
    margin: '30px 0'
  },
  testimonialsContainer: {
    backgroundColor: '#611a87',
    marginBottom: '40px'
  },
}))

const HomePage = () => {
  const classes = useStyles();
  const images = useStaticQuery(graphql`
    query {
      guiterWhite: file(relativePath: { eq: "courses/guiter_white.png" }) {
        childImageSharp {
          fixed(width: 100 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      guiterTurquoise: file(relativePath: { eq: "courses/guiter_turquoise.png" }) {
        childImageSharp {
          fixed(width: 100 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      pianoWhite: file(relativePath: { eq: "courses/piano_white.png" }) {
        childImageSharp {
          fixed(width: 80 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      pianoTurquoise: file(relativePath: { eq: "courses/piano_turquoise.png" }) {
        childImageSharp {
          fixed(width: 80 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      vocalWhite: file(relativePath: { eq: "courses/vocal_white.png" }) {
        childImageSharp {
          fixed(width: 80 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      vocalTurquoise: file(relativePath: { eq: "courses/vocal_turquoise.png" }) {
        childImageSharp {
          fixed(width: 80 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      background: file(relativePath: { eq: "main/background1.png" }) {
        childImageSharp {
          fluid(maxWidth: 2400 ) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      group: file(relativePath: { eq: "home/group.png" }) {
        childImageSharp {
          fluid(maxWidth: 400 ) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      note1: file(relativePath: { eq: "home/note1.png" }) {
        childImageSharp {
          fixed(width: 400 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      note2: file(relativePath: { eq: "home/note2.png" }) {
        childImageSharp {
          fixed(width: 150 ) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
  <Layout>
    <SEO title='HOME' />
    <div id="mainbanner">
      <Banner />
    </div>
    <div style={{ width: '100%', backgroundColor: '#f7f0e3' }}>
      <div className={compStyles.seperator} style={{ width: '300px', margin: 'auto' }}></div>
    </div>
    <section className={classes.coursesContainer}>
      <Typography variant="h2" className={classes.title}>WE TEACH</Typography>
      <div className={classes.courses}>
        <div className={classes.course}>
          <Link to="/exploreCourses?type=0">
            <div className={compStyles.titleContainer}>
              <Img fluid={images.background.childImageSharp.fluid} className={classes.titleBackground} />
              <Img fixed={images.guiterWhite.childImageSharp.fixed} className={compStyles.titleImage} />
              <Img fixed={images.guiterTurquoise.childImageSharp.fixed} className={compStyles.titleImageHover} />
            </div>
          </Link>
          <div className={classes.courseText}>
            <Typography variant="body1">GUITAR FOUNDATION</Typography>
            <Typography variant="body2">Our beginner lessons are perfect for anyone looking to learn basic guitar concepts</Typography>
          </div>
        </div>
        <div className={classes.course}>
          <Link to="/exploreCourses?type=1">
            <div className={compStyles.titleContainer}>
              <Img fluid={images.background.childImageSharp.fluid} className={classes.titleBackground} />
              <Img fixed={images.pianoWhite.childImageSharp.fixed} className={compStyles.titleImage} />
              <Img fixed={images.pianoTurquoise.childImageSharp.fixed} className={compStyles.titleImageHover} />
            </div>
          </Link>
          <div className={classes.courseText}>
            <Typography variant="body1">PIANO FOUNDATION</Typography>
            <Typography variant="body2">This course is ideal for students looking to take their first steps into the world of Western Classical Music (Piano).</Typography>
          </div>
        </div>
        <div className={classes.course}>
          <Link to="/exploreCourses?type=2">
            <div className={compStyles.titleContainer}>
              <Img fluid={images.background.childImageSharp.fluid} className={classes.titleBackground} />
              <Img fixed={images.vocalWhite.childImageSharp.fixed} className={compStyles.titleImage} />
              <Img fixed={images.vocalTurquoise.childImageSharp.fixed} className={compStyles.titleImageHover} />
            </div>
          </Link>
          <div className={classes.courseText}>
            <Typography variant="body1">VOCAL FOUNDATION</Typography>
            <Typography variant="body2">Our beginner lessons are perfect for anyone looking to learn basic vocal concepts</Typography>
          </div>
        </div>
      </div>
      <Link to="/exploreCourses" className={classes.coursesButtonContainer}>
        <Typography variant="body2" className={classes.coursesButton}>VIEW COURSES</Typography>
      </Link>
    </section>
    <section className={classes.aboutContainer}>
      <Typography variant="h2" className={classes.title}>ABOUT US</Typography>
      <div className={classes.aboutDetails}>
        <Img fixed={images.group.childImageSharp.fluid} className={classes.groupPhoto} />
        <Typography variant="body2" className={classes.aboutText1}>Sound Garage is the brainchild of Saurabh Aggarwal, who’s a qualified Trinity College London Pianist, Audio Engineer & aspiring music composer.</Typography>
        <Typography variant="body2" className={classes.aboutText2}>Founded as a music school in the year 2012, we quickly became one of the well-known institutions in Chennai, loved for our unique approach and effective teaching methods that not only helped our students to play the instruments but also create and perform.</Typography>
        <Typography variant="body2" className={classes.aboutText2}>Since 2020, a lot has changed in the way of teaching, and so have we, but in a good and effective way. We have found that as far as online music instrument training is concerned there's no negative impact for the students; in fact, it is a more efficient, time-and cost-effective way to learn.</Typography>

        <Img fixed={images.note2.childImageSharp.fixed} />

        <Typography variant="body1" className={classes.aboutText3}>OUR MISSION</Typography>
        <Typography variant="body1" className={classes.aboutText4}>The mission of Sound Garage is to provide young musicians who demonstrate the potential to become exceptional (irrespective of background) with outstanding teaching within a supportive school environment and to equip them with the self-confidence, adaptability, maturity and perspective to sustain their professional development and personal Fulfillment throughout their lives.</Typography>

        <Typography variant="body1" className={classes.aboutText3}>OUR VISION</Typography>
        <Typography variant="body1" className={classes.aboutText4}>We intend to develop our students into artists who are capable of composing and performing this art form with the highest possible technical abilities and fluency, so the students can discover the most expressive and universal language of mankind called music, at its true depth.</Typography>

        <Img fixed={images.note1.childImageSharp.fixed} />

      </div>
      <div className={classes.aboutBottom} />
    </section>
    <hr className={classes.divider} />
    <section>
      <Typography variant="h2" className={classes.title}>PERFORMANCES</Typography>
      <div id="performances" className={classes.gallery}>
        <Gallery showNavButton={true} />
      </div>
    </section>
    <hr className={classes.divider} />
    <section className={classes.testimonialsContainer}>
      <Typography variant="h2" className={classes.titleWhite}>TESTIMONIALS</Typography>
      <div className={classes.gallery} id="testimonials">
        <Testimonials showNavButton={true} />
      </div>
    </section>
  </Layout>
  )
}

export default HomePage
