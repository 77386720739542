import React from 'react';
import compStyles from './BannerText.module.scss'
import Typography from '../../components/Typography';

export const BannerText = (props) => {
  const { children = '', className, animation, variant } = props
  const words = children.split(' ')

  return (
    <Typography variant={variant} className={`${className} ${compStyles.wrapper}`}>
      {
        words.map((word) => {
          return (<>
            {word.split('').map(char => (<span key={char}>{char}</span>))}
            <span>&nbsp;</span>
          </>)
        })
      }
    </Typography>
  )
}

export default BannerText