import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Typography from '../../components/Typography'
import Carousel from '../../components/Carousel';
import BannerText from './BannerText'
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../variables/colors';
import compStyles from './Banner.module.scss'


const useStyles = makeStyles({
  bannerImage: {
    overflow: 'hidden',
    position: 'relative',
    display: 'none',
    '@media screen and (min-width: 599px)': {
      display: 'block'
    }
  },
  bannerImage__image: {
    height: 'calc(100vh - 100px) !important',
  },
  bannerImageMobile: {
    overflow: 'hidden',
    position: 'relative',
    '@media screen and (min-width: 599px)': {
      display: 'none',
    }
  },
  bannerImageMobile__image: {
    width: '100vw !important',
  },
  secondbanner: {
    marginTop: '-50px'
  },
  textOnImage: {
    maxWidth: '60vw',
    color: '#ddd', 
    position: 'absolute', 
    bottom: '15%', 
    left: '10%',
  },
  prevButton: {
    backgroundColor: 'orange'
  },
  carouselItem: {
    position: 'relative',
    fontFamily: 'Neuton !important'
  },
  carouselText: {
    position: 'absolute',
    top: '0',
    left: '5%',
    width: '90%',
    '@media screen and (min-width: 599px)': {
      left: '10%',
    }
  },
  mainText1: {
    width: 'fit-content',
    color: '#fff',
    fontWeight: '600',
    fontSize: '3em',
    fontFamily: 'Neuton',
    letterSpacing: '4px', 
    lineHeight: '1',
    '@media screen and (min-width: 599px)': {
      fontSize: '4rem'
    }    
  },
  mainText2: {
    width: 'fit-content',
    color: '#27eed7',
    fontWeight: '600',
    fontFamily: 'Neuton',
    fontSize: '3rem',
    letterSpacing: '4px',
    lineHeight: '1',
    '@media screen and (min-width: 599px)': {
      fontSize: '4rem'
    }  
  },
  mainText3: {
    fontSize: '3em',
    lineHeight: '1.2',
    color: 'orange',
    fontFamily: 'Brush Script MT, Brush Script Std, cursive',
    '@media screen and (min-width: 599px)': {
      fontSize: '4em'
    }
  },
  mainText4: {
    maxWidth: '600px'
  },
  mainText3Container: {
    textAlign: 'center',
    marginTop: '60px',
    '@media screen and (min-width: 599px)': {
      maxWidth: '900px',
      marginTop: '125px',
    }
  },
  mainText5Container: {
    position: 'absolute',
    bottom: '75%',
    right: '0'
  },
  mainText5: {
    width: '600px',
    fontSize: '4rem',
    color: colors.primary,
    letterSpacing: '4px',
    textAlign: 'center',
    fontWeight: '600'
  },
  mainText6Container: {
    position: 'absolute',
    bottom: '75%',
    right: '-90px'
  },
  '@media screen and (min-width: 599px)': {
    bannerImage: {
      width: '100%',
      height: 'calc(100% - 160px)',
      maxHeight: 'calc(100vh - 160px)',
    },
    carouselItem: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative'
    },
    mainText3: {
      lineHeight: '1.5',
    },
    mainText5Container: {
      bottom: '50%',
    },
    mainText6Container: {
      position: 'absolute',
      bottom: '75%',
      right: '0'
    }
  },
  '@media screen and (min-width: 1299px)': {
    mainText3: {
      margin: '0'
    }
  },
  backedby: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  backed: {
    width: '120px',
    margin: '20px',
    '@media screen and (min-width: 599px)': {
      width: '200px'
    }
  },
  backedTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  }
});

// placeholderImage: file(relativePath: { eq: "banner/banner1.jpg" }) {
const Banner = (props) => {
  const { showNavButton = false } = props
  const classes = useStyles();

  const bannerImages = useStaticQuery(graphql`
    query {
      banner1: file(relativePath: { eq: "banner/banner1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      mobilebanner1: file(relativePath: { eq: "banner/mobilebanner1.png" }) {
        childImageSharp {
          fluid(quality:100, maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      banner2: file(relativePath: { eq: "banner/banner2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      mobilebanner2: file(relativePath: { eq: "banner/mobilebanner2.png" }) {
        childImageSharp {
          fluid(quality:100, maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      banner3: file(relativePath: { eq: "banner/banner3.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      mobilebanner3: file(relativePath: { eq: "banner/mobilebanner3.png" }) {
        childImageSharp {
          fluid(quality:100, maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      trinity: file(relativePath: { eq: "banner/trinity.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      ucas: file(relativePath: { eq: "banner/ucas.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return(
    <Carousel
      autoPlay={true}
      animation={'fade'}
      indicators={true}
      navButtonsAlwaysVisible={showNavButton}
      navButtonsProps={{
        style: classes.prevButton
      }}
      interval={7000}
      timeout={1500}
      navButtonsWrapperProps={{
        style: {
          color: 'red'
        }
      }}
    >
      {
        ['banner1', 'banner2', 'banner3'].map( (banner, index) => {
          return (
            <div key={index} className={classes.carouselItem}>
              <div className={classes.bannerImage}>
                <Img className={`${classes.bannerImage__image} ${index === 1 ? classes.secondbanner : ''}`} fluid={bannerImages[banner].childImageSharp.fluid} />
              </div>
              <div className={classes.bannerImageMobile}>
                <Img className={classes.bannerImageMobile__image} fluid={bannerImages[`mobile${banner}`].childImageSharp.fluid} />
              </div>
              <div className={index === 2 ? classes.mainText5Container : classes.carouselText}>
                {
                index === 0 && (<><BannerText variant="h1" className={classes.mainText1}>Be a Complete</BannerText>
                  <BannerText variant="h1" className={classes.mainText2}>Musician</BannerText>
                </>)
                }
                {
                index === 1 && (<div className={classes.mainText3Container}>
                  {/* <Typography variant="h2" className={classes.mainText3}>A skill for a bright future</  Typography>
                  <Typography variant="body1">Our curriculum is backed by the syllabus of Trinity College London. Music performance & Theory which are included in the UK Universities and Colleges Admissions Service (UCAS) tariff, and attract UCAS points for university and higher education entry.</Typography>
                  <div className={classes.backedTitle}>
                    <Typography variant="body1">Backed by:</Typography>
                    <div className={classes.backedby}>
                      <Img className={classes.backed} fluid={bannerImages.trinity.childImageSharp.fluid} />
                      <Img className={classes.backed} fluid={bannerImages.ucas.childImageSharp.fluid} />
                    </div>
                  </div> */}
                </div>)
                }
                {
                index === 2 && (<div className={classes.mainText6Container}>
                  <BannerText variant="h1" className={classes.mainText5}>Be a</BannerText>                  
                  <BannerText variant="h1" className={classes.mainText5}>Composer</BannerText>                  
                </div>)
                }
              </div>
            </div>
          )
        })
      }
    </Carousel>
  )
}

export default Banner;