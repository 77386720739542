import React from 'react';

import Typography from '../../components/Typography'
import Carousel from '../../components/Carousel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  prevButton: {
    backgroundColor: 'orange'
  },
  carouselItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: '10px'
  },
  video: {
    width: '80%',
    height: '400px',
    maxWidth: '500px',
    marginBottom: '10px'
  },
  '@media screen and (min-width: 599px)': {
    bannerImage: {
      width: '100%',
      height: '100%',
      maxHeight: 'calc(90vh - 64px)',
    }
  }
});

const resources = [{
  name: 'Yashika Ravishankar',
  age: 'Age 12 Years Old',
  location: 'United States',
  video: 'gallery/4.mp4'
}, {
  name: 'Raisa Khanna',
  age: '7 Years Old',
  location: 'Ludhiana, India',
  video: 'gallery/1.mp4'
}, {
  name: 'Ayaan Satya Kumar',
  age: '10 Years Old',
  location: 'Chennai, India',
  video: 'gallery/5.mp4'
}, {
  name: 'Arshdeep Singh Kalra',
  age: '11 Years Old',
  location: 'New Delhi, India',
  video: 'gallery/2.mp4'
}, {
  name: 'Shaurya Singh Kalra',
  age: '8 Years Old',
  location: 'New Delhi, India',
  video: 'gallery/3.mp4'
}]

const Gallery = (props) => {
  const { showNavButton = false } = props
  const classes = useStyles();

  return(
    <Carousel
      autoPlay={true}
      animation={'fade'}
      indicators={true}
      navButtonsAlwaysVisible={showNavButton}
      navButtonsProps={{
        style: classes.prevButton
      }}
      interval={3 * 60000}
      timeout={1500}
    >
      {
        resources.map((resource, index) => {
          const { name, age, location, video } = resource
          return (
            <div key={index}>
              <div className={classes.carouselItem}>
                <video className={classes.video} controls>
                  <source src={video} type="video/mp4" />
                </video>
                <div>
                  <Typography variant="body2">{name}</Typography>
                  <Typography variant="subtitle1">{age}</Typography>
                  <Typography variant="subtitle1">{location}</Typography>
                </div>
              </div>
            </div>
          )
        })
      }
    </Carousel>
  )
}

export default Gallery;